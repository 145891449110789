ContinuousDeliveryMetricsApp.Views.EntitySearch = Backbone.View.extend({
    className: "col-lg-12",

    events: {
        "click .cd-metrics-entity-search": "cdSearchEntity",
        "keyup #input-entity-id": "keyPressEventHandler"
    },

    initialize: function() {
        var self = this;

        $('.entity-detail-search').append(this.$el);

        $.ajax({
          url: '/assets/js/admin/continuous-delivery-metrics/03_templates/entity-search.html',
          success: function(extTemplate) {
            self.template = _.template(extTemplate);
            self.$el.html(self.template)
          },
          cache: false
        });
    },

    cdSearchEntity: function() {
        var entityIdElement = this.$el.find('.cd-search-entity-id');
        var entityId = entityIdElement.val();

        var entitySearchResults = 'initial state';
        $.ajax({
            url: "/api/continuous-delivery-metrics/entity-search/" + entityId,
            success: function(response) {
                let results = { entitySearchResults: 'None Found' };
                if (response.length > 0) {
                  results.entitySearchResults = response;
                }
                new ContinuousDeliveryMetricsApp.Views.EntitySearchResponse({ el: $('.cd-metrics-entity-results'), model: results });
            },
            cache: false
        });
    },

    keyPressEventHandler: function(event) {
        if (event.keyCode === 13) {
            this.cdSearchEntity();
        }
    },

    close: function() {
        this.remove();
        this.unbind();
    }
});