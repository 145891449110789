ContinuousDeliveryMetricsApp.Models.BreakerSevenDays = Backbone.Model.extend({
    
    url: function() {
        return "/api/continuous-delivery-metrics/breaker/last-seven-days"
    },
    
    defaults: {
        breakerSevenDays: ['initial state']
    },

    parse: function (data) {
        let model = {};
        model.breakerSevenDays = data;
        return model;
    }
});