ContinuousDeliveryMetricsApp.Views.EntitySearchResponse = Backbone.View.extend({

    initialize: function() {
        var self = this;
        self.model.initialState = true;

        $.ajax({
            url: '/assets/js/admin/continuous-delivery-metrics/03_templates/entity-search-results.html',
            success: function(extTemplate) {
                self.template = _.template(extTemplate);
                self.model.initialState = false;
                self.render();
            },
            cache: false
        });
    },

    render: function() {
        this.$el.html(this.template(this.model));

        if (this.model.initialState === false) {
            $('.entity-search-results-wrapper').toggleClass('sk-loading');
        }
    },

    close: function() {
        this.remove();
        this.unbind();
    }
});