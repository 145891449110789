ContinuousDeliveryMetricsApp.Models.LastSevenDays = Backbone.Model.extend({
    
    url: function() {
        return "/api/continuous-delivery-metrics/last-seven-days"
    },
    
    defaults: {
        lastSevenDays: ['initial state']
    },

    parse: function (data) {
        var model = {};
        model.lastSevenDays = data;
        return model;
    }
});