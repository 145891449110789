ContinuousDeliveryMetricsApp.Models.BreakerReasonCodes = Backbone.Model.extend({
    
    url: function() {
        return "/api/continuous-delivery-metrics/breaker/reason-codes"
    },
    
    defaults: {
        breakerReasonCodes: ['initial state']
    },

    parse: function (data) {
        let model = {};
        model.breakerReasonCodes = data;
        return model;
    }
});